import React, { useState } from "react";
import { Camera, Download, Settings2, Coffee, Heart } from "lucide-react";

const QRGenerator = () => {
  const [text, setText] = useState("");
  const [qrSize, setQrSize] = useState(200);
  const [isGenerating, setIsGenerating] = useState(false);

  const trackQRGeneration = async () => {
    try {
      const response = await fetch("https://www.qr-now.com/track-qr.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        mode: "cors",
        body: JSON.stringify({
          text: text,
        }),
      });

      const data = await response.json();
      if (!data.success) {
        console.error("Tracking failed");
      }
    } catch (error) {
      console.error("Tracking error:", error);
    }
  };

  const getQRCode = (format = "svg") => {
    if (text && !isGenerating) {
      setIsGenerating(true);
      trackQRGeneration().finally(() => setIsGenerating(false));
    }

    const baseUrl = "https://api.qrserver.com/v1/create-qr-code/";
    const params = new URLSearchParams({
      data: text,
      size: `${qrSize}x${qrSize}`,
      format: format,
    });
    return `${baseUrl}?${params.toString()}`;
  };

  const handleDonation = () => {
    window.open("https://www.paypal.com/paypalme/mpsf2024/2", "_blank");
  };

  const downloadQR = (format) => {
    const link = document.createElement("a");
    link.href = getQRCode(format);
    link.download = `qr-code.${format}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    if (text) {
      trackQRGeneration();
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <header className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 py-4 sm:px-6 lg:px-8 flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <Camera className="h-8 w-8 text-blue-600" />
            <h1 className="text-2xl font-bold text-gray-900">QR Now!</h1>
          </div>
          <Settings2 className="h-6 w-6 text-gray-500 cursor-pointer hover:text-gray-700" />
        </div>
      </header>

      <div className="max-w-7xl mx-auto px-4 py-4">
        <div className="w-full h-24 bg-gray-100 rounded-lg flex items-center justify-center text-gray-400 border-2 border-dashed">
          Ad Space
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 text-center">
        <h2 className="text-2xl font-semibold text-gray-800 mb-2">
          🚀 Generate QR Codes Instantly!
        </h2>
        <p className="text-lg text-gray-600 max-w-3xl mx-auto leading-relaxed">
          Welcome to QR Now! ✨ Create unlimited QR codes completely free,
          forever! Perfect for businesses, personal use, or sharing information
          quickly. Download in multiple formats (SVG, PNG, JPG) and customize
          sizes to fit your needs. No registration required! 🎯 Start creating
          your QR codes now! 🎨
        </p>
      </div>

      <main className="max-w-7xl mx-auto px-4 py-8 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Enter text or URL
              </label>
              <textarea
                value={text}
                onChange={(e) => setText(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm h-32"
                placeholder="Enter the text you want to convert to QR code..."
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                QR Code Size: {qrSize}x{qrSize}
              </label>
              <input
                type="range"
                min="100"
                max="400"
                value={qrSize}
                onChange={(e) => setQrSize(e.target.value)}
                className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
              />
            </div>

            <div className="flex flex-wrap gap-4">
              <button
                onClick={() => downloadQR("svg")}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
              >
                <Download className="h-4 w-4 mr-2" />
                Download SVG
              </button>
              <button
                onClick={() => downloadQR("png")}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700"
              >
                <Download className="h-4 w-4 mr-2" />
                Download PNG
              </button>
              <button
                onClick={() => downloadQR("jpg")}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700"
              >
                <Download className="h-4 w-4 mr-2" />
                Download JPG
              </button>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center space-y-4">
            <div className="bg-white p-4 rounded-lg shadow-lg">
              {text && (
                <img
                  src={getQRCode("svg")}
                  alt="Generated QR Code"
                  className="max-w-full h-auto"
                />
              )}
              {!text && (
                <div className="w-[200px] h-[200px] flex items-center justify-center text-gray-400 border-2 border-dashed rounded">
                  QR Preview
                </div>
              )}
            </div>

            <div className="text-center mt-4 space-y-3">
              <p className="text-sm text-gray-500">
                Love this free tool? Help keep it running! ❤️
              </p>
              <div className="flex items-center justify-center space-x-4">
                <button
                  onClick={handleDonation}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600 transition-colors"
                >
                  <Coffee className="h-4 w-4 mr-2" />
                  Buy me a coffee (2€)
                </button>
              </div>
              <p className="text-xs text-gray-400">
                Supporting our ads helps keep this service free for everyone
              </p>
            </div>
          </div>
        </div>
      </main>

      <div className="max-w-7xl mx-auto px-4 py-4">
        <div className="w-full h-24 bg-gray-100 rounded-lg flex items-center justify-center text-gray-400 border-2 border-dashed">
          Ad Space
        </div>
      </div>

      <footer className="bg-white mt-8">
        <div className="max-w-7xl mx-auto px-4 py-4 sm:px-6 lg:px-8">
          <p className="text-center text-gray-500 text-sm">
            © 2024 QR Now! - Generate QR codes instantly
          </p>
        </div>
      </footer>
    </div>
  );
};

export default QRGenerator;
